import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import Faq from "react-faq-component";
import WA_BMSPAF_CHD_Privacy_Notice from '../../Documents/English/WA_BMSPAF_CHD_Privacy_Notice_New.pdf';
import WA_BMSPAF_CHD_Privacy_Notice_Spanish from '../../Documents/Spanish/WA_BMSPAF_CHD_Privacy_Notice_ES_New.pdf';
import NV_BMSPAF_CHD_Privacy_Notice from '../../Documents/English/NV_BMSPAF_CHD_Privacy_Notice_New.pdf';
import NV_BMSPAF_CHD_Privacy_Notice_Spanish from '../../Documents/Spanish/NV_BMSPAF_CHD_Privacy_Notice_ES_New.pdf';

function PrivacyPolicy({ pageData, lang}) {
  const backToTop = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };
  const openRenewDocument = (documentTitle) => {
    let url = ' ';
    if (documentTitle === 'Washington') {
       url =
          lang === 'english'
             ? window.open(WA_BMSPAF_CHD_Privacy_Notice)
             : window.open(WA_BMSPAF_CHD_Privacy_Notice_Spanish);
    } else if (documentTitle === 'Nevada') {
       url =
          lang === 'english'
             ? window.open(NV_BMSPAF_CHD_Privacy_Notice)
             : window.open(NV_BMSPAF_CHD_Privacy_Notice_Spanish);
    }
  }
  const sectionOne = {
    title: pageData.questionSections.section1.title,
    rows: [
      {
        title: pageData.questionSections.section1.rows[0].title,
        content: (
          <p> {pageData.questionSections.section1.rows[0].content}
            <Link to="#" onClick={() =>
                                                openRenewDocument('Washington')
                                             }>
              <span class="bold-span">{lang === 'english' ? 'Washington Consumer Health Data Privacy Notice (PDF)' : 'Aviso de política de privacidad de datos de salud del consumidor de Washington (PDF)'}</span>
            </Link>
            .
          </p>
        ),
      },
      {
        title: pageData.questionSections.section1.rows[1].title,
        content: (
          <p> {pageData.questionSections.section1.rows[1].content}
            <Link to="#" onClick={() =>
                                                openRenewDocument('Nevada')
                                             }>
              <span class="bold-span">{lang === 'english' ? 'Nevada Consumer Health Data Privacy Notice (PDF)' : 'Aviso de política de privacidad de datos de salud del consumidor de Nevada (PDF)'}</span>
            </Link>
            .
          </p>
        ),
      },
    ],
    styles: {
      titleTextColor: "#3E000C",
      titleTextSize: "25px",
    },
  };

  const config = {
    arrowIcon: " ",
    tabFocus: true
  };



  return (
    <main id="main" className="privacy-main-div">
      <div className="container mt-5">
        <div className="row g-0">
          <div className="privacy-content-body col-md-8 col-sm-12 mx-auto">
          
            <div className="privacy-content1">
              <h1>{pageData.privacyContent.heading}</h1>
              <p>{pageData.privacyContent.content}</p>
            </div>
              <div className="faq-content col-sm-12 col-md-12 mx-auto mt-0">
                <Faq id="r1" data={sectionOne} config={config} />
              </div>
            <div className="privacy-content1">
              <h2>{pageData.bmspafPrivacyPolicy.title}</h2>
              <p>{pageData.bmspafPrivacyPolicy.description}</p>
            </div>
            <div className="privacy-content1">
              <h3>{pageData.infoWeCollect.title}</h3>
              <p>{pageData.infoWeCollect.description}</p>
              <p><div dangerouslySetInnerHTML={{ __html: pageData.infoWeCollect.content }}></div></p>
            </div>
            <div className="privacy-content1">
              <h3>{pageData.howWeUseInfo.title}</h3>
              <p>{pageData.howWeUseInfo.description}</p>
              <p><div dangerouslySetInnerHTML={{ __html: pageData.howWeUseInfo.content }}></div></p>
            </div>
            <div className="privacy-content1">
              <h3>{pageData.howWeDiscloseInfo.title}</h3>
              <p>{pageData.howWeDiscloseInfo.description}</p>
              <p><div dangerouslySetInnerHTML={{ __html: pageData.howWeDiscloseInfo.content }}></div></p>
            </div>
            <div className="privacy-content1">
              <h3>{pageData.cookiesTrackTech.title}</h3>
              <p><div dangerouslySetInnerHTML={{ __html: pageData.cookiesTrackTech.description }}></div></p>
            </div>
            <div className="privacy-content1">
              <h3>{pageData.childrenPrivacy.title}</h3>
              <p>{pageData.childrenPrivacy.description }</p>
            </div>
            <div className="privacy-content1">
              <h3>{pageData.otherWebLinks.title}</h3>
              <p>{pageData.otherWebLinks.description }</p>
            </div>
            <div className="privacy-content1">
              <h3>{pageData.yourRightsChoices.title}</h3>
              <p>{pageData.yourRightsChoices.description}</p>
              <p><div dangerouslySetInnerHTML={{ __html: pageData.yourRightsChoices.content }}></div></p>
              <p>{pageData.yourRightsChoices.extraDescription}</p>
            </div>
            <div className="privacy-content1">
              <h3>{pageData.securityText.title}</h3>
              <p>{pageData.securityText.description }</p>
            </div>
            <div className="privacy-content1">
              <h3>{pageData.retention.title}</h3>
              <p><div dangerouslySetInnerHTML={{ __html: pageData.retention.description }}></div></p>
            </div>
            <div className="privacy-content1">
              <h3>{pageData.consumerHealthData.title}</h3>
              <p>{pageData.consumerHealthData.description }</p>
            </div>
            <div className="privacy-content1">
              <h3>{pageData.privacyPolicyUpdates.title}</h3>
              <p>{pageData.privacyPolicyUpdates.description }</p>
            </div>
            <div className="privacy-content1">
              <h3>{pageData.contactUs.title}</h3>
              <p>{pageData.contactUs.description }</p>
            </div>
            {/* <div>
              <h2>{pageData.privacyContent.title}</h2>
              <p>{pageData.privacyContent.description}</p>
            </div>
            <div>
              <h2>{pageData.infobyUsers.title}</h2>
              <p>{pageData.infobyUsers.description}</p>
            </div>
            <div>
              <h2>{pageData.personalInfo.title}</h2>
              <p>{pageData.personalInfo.description}</p>
            </div>
            <div>
              <h2>{pageData.nonpersonalInfo.title}</h2>
              <p>{pageData.nonpersonalInfo.description}</p>
            </div>
            <div>
              <h2>{pageData.infowithOthers.title}</h2>
              <p>{pageData.infowithOthers.description}</p>
            </div>
            <div>
              <h2>{pageData.updateInfo.title}</h2>
              <p>{pageData.updateInfo.description}</p>
            </div>
            <div>
              <h2>{pageData.security.title}</h2>
              <p>{pageData.security.description}</p>
            </div>
            <div>
              <h2>{pageData.cookies.title}</h2>
              <p>{pageData.cookies.description}</p>
            </div>
            <div>
              <h2>{pageData.advertising.title}</h2>
              <p>{pageData.advertising.description}</p>
            </div>
            <div>
              <h2>{pageData.beacons.title}</h2>
              <p>{pageData.beacons.description}</p>
            </div>
            <div>
              <h2>{pageData.dataCollection.title}</h2>
              <p>{pageData.dataCollection.description}</p>
            </div>
            <div>
              <h2>{pageData.signals.title}</h2>
              <p>{pageData.signals.description}</p>
            </div>
            <div>
              <h2>{pageData.transfer.title}</h2>
              <p>{pageData.transfer.description}</p>
            </div>
            <div>
              <h2>{pageData.requirements.title}</h2>
              <p>{pageData.requirements.description}</p>
            </div>
            <div>
              <h2>{pageData.enforcement.title}</h2>
              <p>{pageData.enforcement.description}</p>
            </div>
            <div>
              <h2>{pageData.policies.title}</h2>
              <p>{pageData.policies.description}</p>
            </div>
            <div>
              <h2>{pageData.privacytstmt.title}</h2>
              <p>{pageData.privacytstmt.description}</p>
            </div>
            <div>
              <h2>{pageData.privacyforChild.title}</h2>
              <p>{pageData.privacyforChild.description}</p>
            </div>
            <div>
              <h2>{pageData.policySecuritynum.title}</h2>
              <p>{pageData.policySecuritynum.description}</p>
            </div> */}
            {/* <div className="privacy-mail-address">
              <h2>{pageData.privacyMail.heading}</h2>
              <p>
                {pageData.privacyMail.body.map((item, index) => {
                  return <> {item}<br /></>;
                })}
                <a href="mailto:bms@cambridge-partners.com">
                  <span class="bold-span">{pageData.privacyMail.title}</span>
                </a>
              </p> */}
              {/* <p>
                If you have any questions about the Web site's Internet Privacy
                Statement or other aspects of privacy on our Web site, please
                contact us at (800)-332-2056.
                <br />
                Bristol-Myers Squibb Patient Assistance Foundation
                <br />
                P.O. Box 640
                <br />
                Palatine, IL 60078-0640
                <br />
                800-332-2056
                <br />
                <a href="mailto:bms@cambridge-partners.com">
                  <span class="bold-span">E-mail</span>
                </a>
              </p> */}
            {/* </div> */}
          </div>
        </div>
        <div className="privacy-back-to-top row g-0" style={{ marginBottom: "2rem" }}>
          <div className="col-md-8 col-sm-12 mx-auto" >
            <Link
              tabIndex={0}
              style={{
                marginTop: "1em",
                color: "black",
                "border-bottom": "2px solid #EB7100",
                textDecoration: "none",
              }}
              onClick={(e) => backToTop(e)}
            >
              <span class="bold-span">{pageData.backBtnLabel}</span>{" "}
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}

export default PrivacyPolicy;
